<template>
  <div class="home">
    <!-- Banner -->
    <section class="banner">
      <img class="banner-img" src="https://vcos-vds.hydun.com/vds-pub-fonts%2Fsupportcenter%2Fsupport-banner.png" alt="banner" />
      <div class="banner-text">
        <h1 class="banner-text-h1">
          任何困惑，我们都乐意解答
        </h1>
        <p>欢迎访问 VDS 支持中心</p>
      </div>
    </section>

    <!-- 搜索框 -->
    <section class="search-box">
      <input
        class="search-input"
        type="text"
        placeholder="你遇到了什么问题？"
        v-model="searchQuery"
        @keydown.enter="goSearch"
      />
    </section>

    <!-- 常见问题板块 -->
    <section class="common-questions">
      <h2 class="common-questions-title">常见问题</h2>
      <ul class="common-questions-list">
        <li
          v-for="(item, index) in commonQuestions"
          :key="index"
          @click="goTo(item.url)"
        >
          {{ item.title }}
        </li>
      </ul>
    </section>

    <!-- 自助文档卡片 -->
    <h1 class="section-title">遇到了基础或共性问题？找找自助文档</h1>
    <div class="cards">
      <div
        v-for="(app, index) in apps"
        :key="index"
        class="card"
        @mouseenter="onHover(index)"
        @mouseleave="onLeave(index)"
        @click="goTo(app.url)"
        ref="cardEls"
      >
        <img class="icon" :src="app.icon" :alt="app.title" />
        <h2 class="card-title">{{ app.title }}</h2>
      </div>
    </div>

    <!-- 真人帮忙 -->
    <h1 class="section-title">
      遇到文档解决不了的问题？找真人一对一帮你
    </h1>
    <div
      class="support-email"
      ref="supportEmailEl"
      @click="onEmailClick"
    >
      support@vds.pub
      <span class="highlight" ref="highlightEl"></span>
    </div>

    <transition name="fade">
      <p v-if="showExplanation" class="explanation">
        点击这个地址可以给我们发邮件
      </p>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { gsap } from 'gsap'

const router = useRouter()

// 搜索框双向绑定
const searchQuery = ref('')

// 用户按下回车 -> 跳转到搜索结果页: /search?q=xxx
function goSearch() {
  if (!searchQuery.value.trim()) return
  router.push({ 
    name: 'search',      // 假设路由名是 'search'
    query: { q: searchQuery.value.trim() }
  })
}

// 常见问题示例
const commonQuestions = ref([
  // { title: '如何安装 VDS？', url: '/docs/install' },
  // { title: '常见报错及排查方法', url: '/docs/errors' },
  { title: '主播门户新手指南', url: '/docs/read?path=LiveScope/%E4%B8%BB%E6%92%AD%E7%9B%B8%E5%85%B3&name=%E4%B8%BB%E6%92%AD%E9%97%A8%E6%88%B7%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97' },
  { title: '为什么我不能开启多个同时直播？', url: '/docs/read?path=LiveScope/%E4%B8%BB%E6%92%AD%E7%9B%B8%E5%85%B3&name=%E7%9B%B4%E6%92%AD%E9%85%8D%E9%A2%9D' },
  { title: '直播间申请文档和常见问题解答', url: '/docs/read?path=LiveScope/%E4%B8%BB%E6%92%AD%E7%9B%B8%E5%85%B3&name=%E7%9B%B4%E6%92%AD%E9%97%B4%E7%94%B3%E8%AF%B7' },
  { title: 'LiveScope 嵌入式播放器接入指南', url: '/docs/read?path=VDS%20Developer%20Platform/能力开发文档&name=LiveScope%20嵌入式播放器接入指南' }
])

function goTo(path) {
  // 这里你可以 router.push(path)
  router.push(path)
}

// 文档卡片
const apps = ref([
  {
    icon: 'https://vcos-vds.hydun.com/vds-pub-fonts%2Fsupportcenter%2Flivescope.png',
    title: 'LiveScope',
    url: '/docs/read?path=LiveScope'
  },
  {
    icon: 'https://vcos-vds.hydun.com/vds-pub-fonts%2Fsupportcenter%2Fvdp.png',
    title: 'VDS Developer Platform',
    url: '/docs/read?path=VDS Developer Platform'
  }
])

// 卡片 DOM
const cardEls = ref([])

function onHover(index) {
  gsap.to(cardEls.value[index], { scale: 1.03, duration: 0.3 })
}
function onLeave(index) {
  gsap.to(cardEls.value[index], { scale: 1, duration: 0.3 })
}

// support email
const supportEmailEl = ref(null)
const highlightEl = ref(null)
const hasAnimated = ref(false)
const showExplanation = ref(false)

function onEmailClick() {
  window.location.href = 'mailto:support@vds.pub'
}

onMounted(() => {
  document.title = "支持中心 - VDS·欢乐，正发生。";
  // IntersectionObserver 监听 supportEmailEl
  const observer = new IntersectionObserver(
    (entries) => {
      const entry = entries[0]
      if (entry.isIntersecting && !hasAnimated.value) {
        hasAnimated.value = true
        gsap.fromTo(
          highlightEl.value,
          { width: 0 },
          {
            width: '100%',
            duration: 1.2,
            ease: 'power4.out',
            onComplete: () => {
              showExplanation.value = true
            }
          }
        )
        observer.disconnect()
      }
    },
    { threshold: 0.2 }
  )
  if (supportEmailEl.value) {
    observer.observe(supportEmailEl.value)
  }
})
</script>

<style scoped>
/* 页面文字白色，可自行设置背景 */
.home {
  color: white;
  text-align: center;
  padding: 20px;
  /* background: #333; */
}

/* Banner（自适应宽高） */
.banner {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.banner-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-text-h1 {
  font-size: 50px;
  font-family: 'MiSans-Bold', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.banner-text p {
  font-size: 16px;
  margin: 5px 0 0;
  padding: 0;
}

/* 搜索框 (可删除) */
.search-box {
  margin-top: 20px;
}

/* 宽搜索框 */
.search-input {
  width: 600px;
  padding: 10px 14px;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 18px;
}

/* 常见问题板块（左对齐） */
.common-questions {
  margin: 40px auto 0;
  width: 600px; /* 根据需要可调 */
  text-align: left;
}

.common-questions-title {
  font-size: 28px;
  margin-bottom: 15px;
  font-family: 'MiSans-Bold', Arial, sans-serif;
}

.common-questions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.common-questions-list li {
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.2s;
}

.common-questions-list li:hover {
  color: #006aff; /* 悬停时高亮，可自行定义 */
}

/* 自助文档卡片 */
.section-title {
  margin-top: 40px;
  font-size: 30px;
  margin-bottom: 20px;
  font-family: 'MiSans-Bold', Arial, sans-serif;
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  transform-origin: center center;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.card-title {
  font-size: 18px;
  margin: 0;
}

/* support email */
.support-email {
  display: inline-block;
  position: relative;
  margin-top: 40px;
  cursor: pointer;
  overflow: hidden;
  font-size: 50px;
  font-family: 'MiSans-Bold', Arial, sans-serif;
}

/* 高亮动画的元素（初始width: 0） */
.highlight {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: yellow;
  z-index: -1;
  opacity: 0.5;
  width: 0;
}

/* 解释文字淡入 */
.explanation {
  margin-top: 10px;
  font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* ----------
   响应式
------------ */
@media (max-width: 1300px) {
  .banner-text-h1 {
    font-size: 32px;
  }

  .search-input {
    width: 90%;
    font-size: 16px;
  }

  .common-questions {
    width: 90%;
  }

  .cards {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .support-email {
    font-size: 32px;
  }

  .section-title {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .banner-text-h1 {
    font-size: 24px;
  }

  .search-input {
    font-size: 14px;
  }

  .support-email {
    font-size: 24px;
  }
}
</style>
