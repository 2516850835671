<template>
    <div id="global-navbar">
        <div id="navbar-background"></div>
        <router-link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 359.3 459.4">
                <path class="cls-1" d="M112.9,348.5L5.5,51.4h32.5l89.8,252.8h2.4L219.9,51.4h32.5l-107.8,297.1h-31.8Z" />
                <path class="cls-1"
                    d="M162.6,349.2v-122.8h37.9c12.7,0,23.6,2.5,32.6,7.4,9,4.9,15.8,12,20.5,21.2,4.7,9.2,7,20.2,7,32.8s-2.5,23.6-7.4,32.8c-4.9,9.2-12,16.3-21.4,21.2-9.3,4.9-20.6,7.4-33.8,7.4h-35.5ZM198,337.4c16,0,28.2-4.3,36.8-12.8s12.8-20.8,12.8-36.8-4.2-28.2-12.5-36.8c-8.3-8.5-20.2-12.8-35.8-12.8h-24.1v99.1h22.8Z" />
                <path class="cls-1"
                    d="M284.7,345.4c-7.6-4-13.7-9.9-18.2-17.6l10.7-6.5c3.6,6,8.2,10.6,13.9,13.7,5.7,3.1,12.1,4.6,19.2,4.6s10.4-.9,14.8-2.8c4.4-1.8,7.8-4.4,10.3-7.7,2.5-3.3,3.7-7,3.7-11.1s-1.1-7.9-3.2-10.9c-2.2-2.9-5.5-5.5-10-7.7-4.5-2.2-10.6-4.4-18.4-6.6-8.2-2.4-14.9-4.8-20-7.1-5.1-2.4-9.4-5.8-12.9-10.3-3.5-4.5-5.2-10.3-5.2-17.6s1.7-12.3,5.2-17.5c3.5-5.2,8.3-9.2,14.6-12.1,6.3-2.9,13.4-4.3,21.5-4.3s16.6,1.8,23.2,5.5c6.6,3.7,12.1,8.8,16.4,15.4l-9.9,7.1c-3.7-5-8.1-8.9-13.4-11.8-5.2-2.9-10.8-4.4-16.8-4.4s-9.9.9-14.3,2.8c-4.3,1.8-7.7,4.4-10.2,7.6-2.5,3.2-3.7,6.8-3.7,10.7s1.4,9,4.1,12.2c2.8,3.2,6.2,5.6,10.4,7.4,4.2,1.7,9.8,3.5,16.7,5.3,8.1,2.3,14.9,4.8,20.3,7.5,5.5,2.7,9.8,6.3,13.1,10.9,3.3,4.5,4.9,10.3,4.9,17.2s-1.7,12.9-5.1,18.1c-3.4,5.1-8.3,9.1-14.6,11.8s-13.7,4.1-22.1,4.1-17.7-2-25.4-6.1Z" />
            </svg>
        </router-link>
        <div id="nav-links">
            <div class="nav-item">
                <a href="#">服务</a>
                <div class="submenu">
                    <div class="submenu-category">
                        <p>频道</p>
                        <a href="https://livescope.tv/room/VDS1">
                            VDS1（LS版）
                            <span v-if="isLive" class="live-badge">正在直播</span>
                        </a>
                        <a href="https://live.bilibili.com/27859584" target="_blank">VDS1（Bilibili版）</a>
                        <!-- <router-link to="/services/vdslivechannel">VDSLive 瞬流频道 (预览)</router-link> -->
                    </div>
                    <div class="submenu-category">
                        <p>平台</p>
                        <a href="https://vdsentnet.com">
                            VDS Entertainment Network 账户
                        </a>
                        <a href="https://developer.vds.pub">
                            VDS Developer Platform
                        </a>
                        <!-- <a href="https://plus.vdsvod.com">VDS Plus（升级中）</a> -->
                    </div>
                    <div class="submenu-category">
                        <p>合作支持</p>
                        <a href="https://livescope.tv">LiveScope</a>
                    </div>
                    <div class="submenu-category">
                        <p>特别活动</p>
                        <router-link to="/vds-events">VDS Events 特别活动</router-link>
                    </div>
                    <div class="submenu-category">
                        <p>线下场地</p>
                        <router-link to="/services/studio-nanjing">
                            VDS Studio Nanjing
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="nav-item">
                <a href="#">节目</a>
                <div class="submenu">
                    <div class="submenu-category">
                        <p>生活</p>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1207729"
                            target="_blank">Q&A Time</a>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1570721"
                            target="_blank">VLOG Time</a>
                    </div>
                    <div class="submenu-category">
                        <p>产品印象&测评</p>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1256486"
                            target="_blank">Open a box 开个箱吧</a>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1247641"
                            target="_blank">科技指南针</a>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1280406"
                            target="_blank">Lazy美食（停止制作）</a>
                    </div>
                    <div class="submenu-category">
                        <p>风景和慢电视</p>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1207711" target="_blank">翱翔·世界</a>
                    </div>
                    <!-- <div class="submenu-category">
                        <p>每周资讯</p>
                        <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1256486"
                            target="_blank">科技第一线</a>
                    </div>
                    <div class="submenu-category">
                        <p>音频播客</p>
                        <a href="111" target="_blank">兽话连篇</a>
                    </div> -->
                    <div class="submenu-category">
                        <p>特别活动</p>
                        <router-link to="/vds-events/replay">在线回放</router-link>
                    </div>
                </div>
            </div>
            <div class="nav-item">
                <a href="#">找到我们</a>
                <div class="submenu">
                    <div class="submenu-category">
                        <p>官方视频账号</p>
                        <a href="https://space.bilibili.com/482125479" target="_blank">Bilibili</a>
                    </div>
                    <div class="submenu-category">
                        <p>自媒体视频账号</p>
                        <a href="https://space.bilibili.com/1645489523" target="_blank">Bilibili</a>
                        <a href="https://www.youtube.com/@VDSpub" target="_blank">YouTube</a>
                    </div>
                    <div class="submenu-category">
                        <p>直播回放视频账号</p>
                        <a href="https://space.bilibili.com/3537111152068630" target="_blank">Bilibili</a>
                    </div>
                    <div class="submenu-category">
                        <p>电子邮件</p>
                        <a href="mailto:baiv@vds.pub" target="_blank">到：创始人</a>
                        <a href="mailto:bd@vds.pub" target="_blank">到：商务合作</a>
                        <a href="mailto:hr@vds.pub" target="_blank">到：人事</a>
                    </div>
                </div>
            </div>
            <div class="nav-item">
                <router-link to="/docs">支持</router-link>
                <div class="submenu">
                    <div class="submenu-category">
                        <p>说明</p>
                    <h1 style="color: white; margin-top: -5px;">任何困惑，我们都乐意解答</h1>
                        <p>来看看通过什么方法能获取帮助和支持信息。</p>
                    </div>
                    <div class="submenu-category">
                        <p>自助支持</p>
                        <router-link to="/docs">查看支持中心</router-link>
                    </div>
                    <div class="submenu-category">
                        <p>邮件一对一支持</p>
                        <a href="mailto:support@vds.pub" target="_blank">发送邮件</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="svg-container"></div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "vc-nav",
    data() {
        return {
            isLive: false, // 状态初始化为 false
        };
    },
    mounted() {
        this.checkLiveStatus();
        this.initializeNavbar();
    },
    methods: {
        checkLiveStatus() {
            // 请求直播状态并设置 isLive 标志
            axios
                .get("https://static.vdsentnet.com/pubapi/check_live_status.php?id=VDS1")
                .then((response) => {
                    if (response.data.status === "on") {
                        this.isLive = true;
                    } else {
                        this.isLive = false;
                    }
                })
                .catch((error) => {
                    console.error("Error checking live status:", error);
                });
        },
        initializeNavbar() {
            const navbar = document.getElementById('global-navbar');
            const navItems = document.querySelectorAll('.nav-item');
            let closeTimeout;

            // 为导航栏中的每个菜单项添加事件监听器
            navItems.forEach(item => {
                const link = item.querySelector('a');
                const submenu = item.querySelector('.submenu');

                // 鼠标悬停时展开子菜单
                link.addEventListener('mouseover', () => {
                    clearTimeout(closeTimeout);
                    navbar.classList.add('expanded');
                    this.hideAllSubmenus();
                    submenu.classList.add('show');
                    submenu.querySelectorAll('.submenu-category').forEach(category => {
                        Array.from(category.querySelectorAll('a')).forEach((child, index) => {
                            child.style.animationDelay = `${index * 0.06}s`;
                        });
                    });
                });

                // 鼠标离开菜单项时延迟关闭子菜单
                item.addEventListener('mouseleave', () => {
                    closeTimeout = setTimeout(() => {
                        if (!document.querySelector('.nav-item:hover') && !document.querySelector('.submenu:hover')) {
                            navbar.classList.remove('expanded');
                            this.hideAllSubmenus();
                        }
                    }, 300);
                });

                // 鼠标悬停在子菜单时取消关闭计时器
                submenu.addEventListener('mouseover', () => {
                    clearTimeout(closeTimeout);
                });

                // 鼠标离开子菜单时延迟关闭子菜单
                submenu.addEventListener('mouseleave', () => {
                    closeTimeout = setTimeout(() => {
                        if (!document.querySelector('.nav-item:hover') && !document.querySelector('.submenu:hover')) {
                            navbar.classList.remove('expanded');
                            this.hideAllSubmenus();
                        }
                    }, 300);
                });
            });

            // 点击页面其他地方时关闭所有子菜单
            document.addEventListener('click', event => {
                if (!navbar.contains(event.target)) {
                    this.hideAllSubmenus();
                    navbar.classList.remove('expanded');
                }
            });

            // 添加对 router-link 点击的监听器，导航成功后收起菜单并滚动到顶部
            this.$router.afterEach(() => {
                this.hideAllSubmenus();
                navbar.classList.remove('expanded');
                window.scrollTo(0, 0); // 滚动到页面顶部
            });
        },
        hideAllSubmenus() {
            // 隐藏所有子菜单并清除动画延迟
            document.querySelectorAll('.submenu').forEach(sub => {
                sub.classList.remove('show');
                sub.querySelectorAll('a').forEach(child => {
                    child.style.animationDelay = '';
                });
            });
        }
    }
};
</script>

<style scoped>
/* VC NAV CSS Ver1.0.0 20240731 VDS Cultural Media */

.live-badge {
  background-color: rgb(255, 73, 73);
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 2px;
}

body {
    margin: 0;
}

#global-navbar {
    font-family: 'Misans-light', Arial, sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;
    /* 保证导航栏在最前 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
}

#navbar-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgba(34, 34, 34, 0.4);
    backdrop-filter: blur(50px);
    z-index: -1;
    /* 放在最底层 */
    transition: height 0.3s ease;
    /* 只过渡高度 */
}

#global-navbar.expanded #navbar-background {
    height: 100vh;
    /* 悬浮时背景展开到全屏高度 */
}

#navbar-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 0.3s ease;
    /* 过渡透明度 */
}

#global-navbar.expanded #navbar-background::before {
    opacity: 1;
}

#global-navbar svg {
    height: 40px;
    /* 调整logo高度 */
    fill: white;
}

#nav-links {
    font-family: MiSans-Regular, Arial, sans-serif;
    display: flex;
    gap: 25px;
    /* 导航选项间距 */
    flex-grow: 1;
    justify-content: center;
    z-index: 1;
    /* 确保在背景之上 */
}

#nav-links a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

/* 取消文字的划线效果 */
#nav-links a::after {
    display: none;
}

/* 子菜单样式 */
.submenu {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    display: flex;
    /* 改为水平排列 */
    flex-direction: row;
    /* 改为水平排列 */
    gap: 30px;
    /* 类与类之间的间距 */
    padding: 20px 15%;
    box-sizing: border-box;
    z-index: 999;
    /* 确保在global-navbar下面一层 */
    opacity: 0;
    pointer-events: none;
    /* 初始不可点击 */
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.submenu-category {
    display: flex;
    flex-direction: column;
}

.submenu-category p {
    font-family: 'MiSans-Light', Arial, sans-serif;
    color: grey;
    margin: 0 0 15px 0;
}

.submenu.show {
    display: flex;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

.submenu a {
    color: white;
    font-size: 14px;
    text-decoration: none;
    opacity: 0;
    margin: 0 0 8px 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.submenu.show a {
    animation: fadeInDown 0.2s forwards;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-8px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 大号屏幕样式 */
@media (min-width: 1024px) {
    #global-navbar {
        padding-left: 15%;
        padding-right: 15%;
    }

    .submenu {
        padding-left: 15%;
        padding-right: 15%;
    }
}

/* 中号屏幕样式 */
@media (min-width: 768px) and (max-width: 1023px) {
    #global-navbar {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* 小号屏幕样式 */
@media (max-width: 767px) {
    #global-navbar {
        padding-left: 20px;
        padding-right: 20px;
        transition: height 0.3s ease; /* 为手机视图添加过渡效果，减少抽搐 */
    }

    #nav-links {
        justify-content: flex-end;
    }

    .submenu {
        flex-direction: column;
        /* 纵向排列 */
        padding-left: 20px;
        padding-right: 20px;
    }

    .submenu-category p,
    .submenu a {
        font-size: 18px;
        /* 增大字号 */
    }
}
</style>