<template>
  <Header />
  <div class="pubcontents">
    <router-view />
  </div>
  <!-- 只在 showFooter 为 true 时渲染 Footer -->
  <Footer v-if="showFooter" />
</template>

<script>
import Header from './components/VC/nav.vue';
import Footer from './components/VC/footer.vue';
import { ref, onMounted, onUnmounted } from 'vue';
// 引入刚才创建的 eventBus
import { eventBus } from '@/utils/eventBus';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  setup() {
    // showFooter 控制 Footer 显示/隐藏
    const showFooter = ref(true);

    // 接收事件后的处理函数
    const handleToggleFooter = (shouldShow) => {
      showFooter.value = shouldShow;
    };

    onMounted(() => {
      // 组件挂载后，监听 'toggle-footer' 事件
      eventBus.on('toggle-footer', handleToggleFooter);
    });

    onUnmounted(() => {
      // 组件卸载时，取消监听，防止内存泄漏
      eventBus.off('toggle-footer', handleToggleFooter);
    });

    return {
      showFooter,
    };
  },
};
</script>

<style>
body {
  font-family: MiSans-Regular, Arial, sans-serif;
  background-color: black; /* 设置背景为纯黑色 */
}

/* 全局样式，移除页面整体的默认边距和内边距 */
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden; /* 避免横向滚动条 */
}

.pubcontents {
  margin-top: 55px;
}
</style>
