<template>
    <div class="search-result">
      <h1 style="font-family: 'MiSans-Bold', Arial, sans-serif;">搜索结果</h1>
  
      <div v-if="loading">加载中...</div>
      <div v-else-if="error">{{ error }}</div>
      <div v-else>
        <ul>
          <li
            v-for="(item, index) in matchedList"
            :key="index"
            @click="goTo(item.path)"
            class="result-line"
          >
            {{ item.title }}
            <!-- 你可显示 item.path -->
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue'
  import axios from 'axios'
  import { useRoute, useRouter } from 'vue-router'
  
  const router = useRouter()
  const route = useRoute()
  
  // 从地址栏 ?q=xxx
  const keyword = ref(route.query.q || '')
  
  const loading = ref(false)
  const error = ref(null)
  const allData = ref([])  // API 返回的整棵文章结构
  
  onMounted(async () => {
    document.title = "搜索文档 - VDS·欢乐，正发生。"
    if (!keyword.value.trim()) {
      error.value = '请输入搜索关键词'
      return
    }
    loading.value = true
    try {
      // 请求文章树
      const res = await axios.get('https://vdsarticlecontents.hydun.com/vpubmd.php')
      allData.value = res.data
    } catch (err) {
      error.value = '加载文章结构失败: ' + err
    } finally {
      loading.value = false
    }
  })
  
  // 递归收集 {title, path} 扁平化
  function collectAllArticles(arr, result) {
    for (const item of arr) {
      if (item.path) {
        result.push({ title: item.title, path: item.path })
      }
      if (item.children && item.children.length > 0) {
        collectAllArticles(item.children, result)
      }
    }
  }
  
  // 根据关键词做模糊匹配
  const matchedList = computed(() => {
    if (!allData.value || !keyword.value.trim()) return []
    const allArticles = []
    collectAllArticles(allData.value, allArticles)
  
    const lowerKey = keyword.value.trim().toLowerCase()
    return allArticles.filter(it => it.title.toLowerCase().includes(lowerKey))
  })
  
  // 点击搜索结果 -> 解析 path => 跳转到 /docs/read?path=xx/yy&name=ZZZ
  function goTo(mdPath) {
    if (!mdPath) return
    // 假设 mdPath 类似: "/111111/222222/女孩第五期活动.md"
    // 1. 去掉开头斜杠, 分割 => ["111111","222222","女孩第五期活动.md"]
    const segments = mdPath.replace(/^\//, '').split('/')
    if (!segments.length) return
  
    // 2. 最后一个是 "xxx.md", 去掉 .md 作为 name
    const lastSeg = segments[segments.length -1]  // e.g. "女孩第五期活动.md"
    const name = lastSeg.replace(/\.md$/i, '')    // e.g. "女孩第五期活动"
  
    // 3. 前面的都是子目录 => path=111111/222222
    const dirSegs = segments.slice(0, -1)         // e.g. ["111111","222222"]
    const subPath = dirSegs.join('/')            // "111111/222222"
  
    // 4. 跳转 => /docs/read?path=111111/222222&name=女孩第五期活动
    router.push({
      path: '/docs/read',
      query: {
        path: subPath,
        name: name
      }
    })
  }
  </script>
  
  <style scoped>
  .search-result {
    color: #fff;
    padding: 20px;
  }
  .result-line {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .result-line:hover {
    color: #ffd500;
  }
  </style>