<template>
  <!-- 外层还是相同：一个 <li class="no-marker"> 包住 -->
  <li class="no-marker">
    <!-- 顶层搜索框 & 标题 -->
    <div v-if="level === 0" class="search-container">
      <h1 style="font-family: 'MiSans-Bold', Arial, sans-serif;">文档浏览器</h1>
      <input
        v-model="searchTerm"
        class="search-input"
        type="text"
        placeholder="搜索..."
      />
    </div>

    <!-- 这里 v-for + key -->
    <template v-for="(item, idx) in finalItems" :key="getKey(item, idx)">
      <!-- 如果 item.isParent => 显示 '..' (返回上级) -->
      <template v-if="item.isParent">
        <li class="tree-item no-marker parent-item" @click.stop="onClickParent">
          <div class="tree-item-label">
            .. (返回上一级)
          </div>
        </li>
      </template>

      <!-- 否则 => 跟原来逻辑: vpubmd / index / normal -->
      <template v-else-if="item.title === 'vpubmd'">
        <!-- ... 跟原先处理 'vpubmd' 一样，不再重复 ... -->
      </template>
      
      <template v-else-if="item.title === 'index'">
        <!-- 隐藏 index.md -->
      </template>
      
      <template v-else>
        <!-- 普通节点 -->
        <li class="tree-item">
          <div
            class="tree-item-label"
            :class="{ active: isActive(item) }"
            @click.stop="onClickItem(item)"
          >
            <span
              v-if="isDirectory(item)"
              class="folder-toggle"
              @click.stop="toggleCollapse(item, idx)"
            >
              {{ isCollapsed(item, idx) ? '[+]' : '[-]' }}
            </span>
            {{ item.title }}
          </div>

          <transition
            name="folder"
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <FileTree
              v-if="isDirectory(item) && !isCollapsed(item, idx)"
              :items="item.children"
              :selectedItem="selectedItem"
              :level="level + 1"
              class="tree-children"
              @select="onClickChild"
            />
          </transition>
        </li>
      </template>
    </template>
  </li>
</template>

<script setup>
import { defineProps, onMounted, defineEmits, reactive, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { gsap } from 'gsap'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  selectedItem: {
    type: Object,
    default: () => ({})
  },
  level: {
    type: Number,
    default: 0
  }
})
const emits = defineEmits(['select'])

const router = useRouter()
const route = useRoute()

const collapseMap = reactive({})
const searchTerm = ref('')

function onClickItem(item) {
  emits('select', item)
}
function onClickChild(childItem) {
  emits('select', childItem)
}
function isDirectory(item) {
  return item.children && item.children.length > 0
}
function isActive(item) {
  return props.selectedItem === item
}
function getKey(item, idx) {
  if (item.path) return item.path
  if (item.isParent) return 'parent-dir'
  return `lvl${props.level}-idx${idx}-${item.title}`
}
function isCollapsed(item, idx, isChildOfVpubmd=false) {
  const k = isChildOfVpubmd
    ? 'child-' + getKey(item, idx)
    : getKey(item, idx)
  return !!collapseMap[k]
}
function toggleCollapse(item, idx, isChildOfVpubmd=false) {
  const k = isChildOfVpubmd
    ? 'child-' + getKey(item, idx)
    : getKey(item, idx)
  collapseMap[k] = !collapseMap[k]
}
function beforeEnter(el) {
  el.style.height = 0
  el.style.overflow = 'hidden'
}
function enter(el, done) {
  gsap.to(el, {
    height: el.scrollHeight,
    duration: 0.4,
    ease: 'power4.out',
    onComplete: () => {
      el.style.height = 'auto'
      el.style.overflow = 'visible'
      done()
    }
  })
}
function leave(el, done) {
  el.style.overflow = 'hidden'
  gsap.to(el, {
    height: 0,
    duration: 0.4,
    ease: 'power4.out',
    onComplete: done
  })
}

// 递归过滤
function filterItems(arr, keyword) {
  if (!keyword) return arr
  const result = []
  for (const it of arr) {
    let matchedSelf = it.title?.toLowerCase().includes(keyword.toLowerCase())
    let newChildren = []
    if (it.children && it.children.length > 0) {
      newChildren = filterItems(it.children, keyword)
    }
    if (newChildren.length > 0) {
      result.push({ ...it, children: newChildren })
      continue
    }
    if (matchedSelf) {
      result.push(it)
    }
  }
  return result
}

const filteredItems = computed(() => {
  return filterItems(props.items, searchTerm.value)
})

/** 
 * 在最外层(level=0) 并且 query有多段 path => 显示 "返回上一级" 虚拟节点
 * 你可在任意 level 都加，但最常见只在 level=0 里
 */
const finalItems = computed(() => {
  // 先拿到过滤后的
  const base = filteredItems.value

  // 若 level>0 => 直接返回 base (子层不加..)
  if (props.level !== 0) {
    return base
  }

  // 仅在顶层时，检查 route.query.path
  let path = route.query.path || ''
  // split => e.g. "111111/222222"
  const segs = path.split('/').filter(s => !!s)
  if (segs.length <= 1) {
    // 没有上一级(或只有1个目录?)
    return base
  }

  // 需要显示 ".." => 这里插入一个 {isParent:true} 虚拟节点
  const parentItem = { title: '..', isParent: true }
  
  // 你也可把 parentItem 放到 base 数组顶部
  return [parentItem, ...base]
})

// 用户点击 ".." => route.query.path => remove last seg => push
function onClickParent() {
  let path = route.query.path || ''
  let segs = path.split('/').filter(s => s)
  if (segs.length > 0) {
    segs.pop() // 移除最后一段
    path = segs.join('/')
  }

  // 复制当前 query，但删除 name
  const newQuery = { ...route.query }
  delete newQuery.name

  // 设置新的 path
  newQuery.path = path

  // 跳转后强制刷新
  router.push({
    path: route.path, // 比如 "/docs/read"
    query: newQuery
  }).then(() => {
    // 等路由跳转完成后，再强制刷新整个页面
    window.location.reload()
  })
}

/** 默认折叠所有子节点(只在 level=0 时) */
onMounted(() => {
  if (props.level === 0) {
    collapseAll(props.items)
  }
})

function collapseAll(arr, isChild=false) {
  arr.forEach((it, idx) => {
    if (isDirectory(it)) {
      const key = isChild
        ? 'child-' + getKey(it, idx)
        : getKey(it, idx)
      collapseMap[key] = true
      // if (it.title === 'vpubmd' ) ...
      if (it.children) {
        collapseAll(it.children, isChild)
      }
    }
  })
}
</script>

<style scoped>
.no-marker {
  list-style-type: none;
}
.search-container {
  padding: 4px 8px;
  margin-bottom: 4px;
  width: 90%;
}
.search-input {
  width: 100%;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.tree-item-label {
  cursor: pointer;
  padding: 4px 8px;
  transition: background-color 0.3s ease; 
}
.tree-item-label:hover {
  background-color: rgba(0, 123, 255, 0.6);
  color: #fff;
}
.tree-item-label.active {
  background-color: #0056b3;
  color: #fff;
}
.tree-children {
  margin-left: 16px;
}
.folder-toggle {
  margin-right: 4px;
  color: #ccc;
}
/* GSAP transition: 'folder' */
.folder-enter-active,
.folder-leave-active {
  transition: none;
}
.folder-enter-from,
.folder-leave-to {
  height: 0;
  overflow: hidden;
}
/* 父目录项可加特别样式 */
.parent-item {
  font-style: italic;
  color: #aaa;
}
</style>