import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 引入 vue3-markdown-it 插件
import Vue3MarkdownIt from 'vue3-markdown-it'

// highlight.js 的主题（可换成其他，如 'atom-one-dark.css'、'github.css' 等）
import 'highlight.js/styles/github-dark.css'

const app = createApp(App)
app.use(router)

// 全局安装 vue3-markdown-it
app.use(Vue3MarkdownIt)

// 挂载
app.mount('#VDSpubAPP'); // 挂载应用