<template>
  <div class="reader-container" :class="{ mobile: isMobile }">
    <!-- 如果是手机 => 显示按钮(Overlay) -->
    <button
      v-if="isMobile"
      class="toggle-button"
      @click="toggleSidebar"
    >
      {{ sidebarVisible ? '收起目录' : '展开目录' }}
    </button>

    <!-- 如果是手机 => overlay; 如果是PC => flex sidebar -->
    <aside
      ref="sidebarEl"
      class="sidebar-left"
      :class="{ overlay: isMobile }"
    >
      <FileTree
        :items="displayedItems"
        :selectedItem="selectedItem"
        @select="onSelectItem"
      />
    </aside>

    <main class="main-content" ref="mainContentEl">
      <div v-if="isStillLoading" class="placeholder">
        请选择一项开始阅读
      </div>
      <div v-else-if="markdownLoading" class="placeholder loading">
        正在加载中，请稍候...
      </div>
      <MdPreview
        v-else
        v-model="markdownText"
        theme="dark"
        :editorId="editorId"
      />
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { eventBus } from '@/utils/eventBus'
import { gsap } from 'gsap'

import FileTree from './FileTree.vue'
import { MdPreview } from 'md-editor-v3'
import 'md-editor-v3/lib/preview.css'

const editorId = 'preview-only'
const markdownText = ref('# Loading...')
const files = ref([])
const selectedItem = ref(null)
const markdownLoading = ref(false)

// 是否手机: <768?
const isMobile = ref(false)
const sidebarVisible = ref(true)

const sidebarEl = ref(null)
const mainContentEl = ref(null)

// API
const listApi = 'https://vdsarticlecontents.hydun.com/vpubmd.php'
const baseUrl = 'https://vdsarticlecontents.hydun.com/vpubmd'

// route
const route = useRoute()
const pathQuery = ref(route.query.path || '')
const nameQuery = ref(route.query.name || '')

onMounted(async () => {
  document.title = "文档阅读器 - VDS·欢乐，正发生。";
  eventBus.emit('toggle-footer', false)

  // 判断是否是手机
  if (window.innerWidth < 768) {
    isMobile.value = true
    sidebarVisible.value = false
    // 侧栏(overlay) 初始 x:-300
    gsap.set(sidebarEl.value, { x: -300 })
  } else {
    isMobile.value = false
    sidebarVisible.value = true
    // PC => display:flex => aside 宽 300, mainContent flex:1
    // 可以直接在CSS中固定 aside=300px, main=flex:1, 不需动画
    // or just do a set if needed
  }

  try {
    const res = await axios.get(listApi)
    files.value = res.data
    if (pathQuery.value) {
      handlePath(pathQuery.value)
    }
  } catch (err) {
    markdownText.value = '加载列表失败: ' + err
  }
})

onBeforeUnmount(() => {
  eventBus.emit('toggle-footer', true)
})

const isStillLoading = computed(() => markdownText.value === '# Loading...')

const displayedItems = computed(() => {
  if (!pathQuery.value) return files.value
  const segs = pathQuery.value.split('/')
  const node = findNodeBySegments(files.value, segs)
  if (node && node.children) return node.children
  return []
})

// watch sidebarVisible => 仅在手机上才执行GSAP overlay动画
watch(sidebarVisible, (visible) => {
  if (!isMobile.value) return // PC不收起, 无动画
  if (visible) {
    gsap.fromTo(
      sidebarEl.value, { x:-300 },
      { x:0, duration:0.5, ease:'power4.out' }
    )
  } else {
    gsap.fromTo(
      sidebarEl.value, { x:0 },
      { x:-300, duration:0.5, ease:'power4.out' }
    )
  }
})

function toggleSidebar() {
  if (!isMobile.value) return // PC不支持收起
  sidebarVisible.value = !sidebarVisible.value
}

async function onSelectItem(item) {
  selectedItem.value = item
  // 若手机 => 自动收起
  if (isMobile.value) {
    sidebarVisible.value = false
  }
  if (item.path) {
    markdownLoading.value = true
    try {
      const url = baseUrl + item.path
      const resp = await axios.get(url)
      markdownText.value = resp.data
    } catch (err) {
      markdownText.value = '加载失败: ' + err
    } finally {
      markdownLoading.value = false
    }
  } else {
    if (item.children && item.children.length>0) {
      const indexNode = item.children.find(c=>c.title==='index')
      if (indexNode && indexNode.path) {
        markdownLoading.value = true
        try {
          const url = baseUrl + indexNode.path
          const resp = await axios.get(url)
          markdownText.value = resp.data
          selectedItem.value = indexNode
        } catch (err) {
          markdownText.value = '加载 index.md 失败: '+ err
        } finally {
          markdownLoading.value = false
        }
      } else {
        markdownText.value = '这是一个目录(无 path)。'
      }
    } else {
      markdownText.value = '这是一个目录(无 path)。'
    }
  }
}

function handlePath(pathStr) {
  const segs = pathStr.split('/')
  const node = findNodeBySegments(files.value, segs)
  if (!node) {
    markdownText.value = '未找到该目录: ' + pathStr
    return
  }
  if (nameQuery.value && node.children) {
    const fileNode = node.children.find(c=>c.title===nameQuery.value)
    if (fileNode && fileNode.path) {
      markdownLoading.value = true
      loadFileContent(fileNode.path).finally(()=>markdownLoading.value=false)
      selectedItem.value = fileNode
    } else {
      markdownText.value = '目录里未找到: ' + nameQuery.value
    }
  }
}

function findNodeBySegments(arr, segs) {
  let currentArr = arr
  let foundItem = null
  for (const seg of segs) {
    foundItem = currentArr.find(x=>x.title===seg)
    if (!foundItem) return null
    if (foundItem.children) {
      currentArr = foundItem.children
    } else {
      return null
    }
  }
  return foundItem
}

async function loadFileContent(mdPath) {
  try {
    const url = baseUrl + mdPath
    const resp = await axios.get(url)
    markdownText.value = resp.data
  } catch (err) {
    markdownText.value = '加载失败: '+ err
  }
}
</script>

<style scoped>
.reader-container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 55px);
  display: flex;         /* PC: flex layout */
  margin: 0; padding: 0;
  box-sizing: border-box;
}

.reader-container.mobile {
  /* 在手机下可改成 display:block + overlay侧栏
     but we'll keep it as well, but .sidebar-left in overlay style */
  display: block; /* or keep flex if you prefer, not mandatory */
}

/* 切换按钮 (只在手机 v-if="isMobile") */
.toggle-button {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 9999;
  background: #444;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

/* PC端: sidebar fixed width=300
   mobile: overlay with transform
*/
.sidebar-left {
  background: #1a1a1a;
  color: #fff;
  overflow-y: auto;
}
/* PC: default width=300, next to main */
.reader-container:not(.mobile) .sidebar-left {
  width: 300px;
}
/* mobile => overlay style => absolute/fixed */
.reader-container.mobile .sidebar-left {
  position: fixed;
  top: 55px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 9998;
}

/* main content => flex:1 in PC, or width:100% in mobile */
.main-content {
  flex: 1; /* PC fill leftover */
  background: #2f2f2f;
  overflow: auto;
  padding: 8px;
  color: #fff;
  width: 100%; /* mobile fallback */
}

.placeholder {
  font-size: 18px;
  color: #aaa;
  margin: 20px;
}
.placeholder.loading {
  color: #fff;
}
</style>